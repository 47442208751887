var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"renseignementsgeneraux"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS GÉNÉRAUX")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"Date d'entrée du voyageur"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Veuillez saisir la date d’entrée du visiteur","max":_vm.today},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"Heure"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Veuillez saisir l’heure d’entrée du visiteur","config":{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                    }},model:{value:(_vm.form.heure),callback:function ($$v) {_vm.$set(_vm.form, "heure", $$v)},expression:"form.heure"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"NPI (Réservé aux Béninois)"}},[_c('validation-provider',{attrs:{"rules":"strictlyNumber|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 10}),expression:"{length: 10}"}],attrs:{"type":"number","placeholder":"Veuillez entrer votre NPI"},model:{value:(_vm.form.npi),callback:function ($$v) {_vm.$set(_vm.form, "npi", _vm._n($$v))},expression:"form.npi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }