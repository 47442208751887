<template>
  <div class="mb-5">
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          enregistrez le formulaire
        </h1>
      </b-col>
    </b-row>
    <strong class="d-flex justify-content-center mb-1">
      Comissariat:<strong>{{
        infos && infos.commissariat && infos.commissariat.nom
      }}</strong>
    </strong>
    <b-card class="preview-document">
      <b-row>
        <b-col cols="12">
          <div v-if="infos" class="pl-1 pr-1">
            <!-- renseignement generaux -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>RENSEIGNEMENTS GÉNÉRAUX </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('renseignements-generaux')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6">
                  Date : <strong> {{ infos.date }}</strong>
                </b-col>
                <b-col md="6">
                  Heure : <strong> {{ infos.heure }}</strong>
                </b-col>

                <b-col md="6">
                  NPI : <strong>{{ infos.npi }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- Identité -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>IDENTITÉ </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('identite')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6">
                  Nom : <strong> {{ infos.lastname }}</strong>
                </b-col>
                <b-col md="6">
                  Prenom : <strong> {{ infos.firstname }}</strong>
                </b-col>
                <b-col md="6">
                  Nom de jeune fille:
                  <strong>{{ infos.maidenname }}</strong>
                </b-col>
                <b-col md="6">
                  Sexe : <strong>{{ infos.sexe }}</strong>
                </b-col>
                <b-col md="6">
                  Date naissance : <strong>{{ infos.dateNaissance }}</strong>
                </b-col>
                <b-col md="6">
                  Lieu naissance : <strong>{{ infos.lieuNaissance }}</strong>
                </b-col>
                <b-col md="6">
                  Nationalite :
                  <strong>{{ infos.nationalite && infos.nationalite }}</strong>
                </b-col>
                <b-col md="6">
                  Adresse à l'étranger :
                  <strong>{{ infos.adresseEtranger }}</strong>
                </b-col>
                <b-col v-if="isBeninResident(infos.paysResidence)" md="6">
                  Mode d'hébergement à l'étranger :
                  <strong>{{
                    infos.modeHebergementEtranger === "Autres"
                      ? infos.autreModeHebergementEtranger
                      : infos.modeHebergementEtranger
                  }}</strong>
                </b-col>
                <b-col md="6">
                  Adresse au Bénin : <strong>{{ infos.adresseBenin }}</strong>
                </b-col>
                <b-col v-if="!isBeninResident(infos.paysResidence)" md="6">
                  Mode d'hébergement au Bénin :
                  <strong>{{
                    infos.modeHebergementBenin === "Autres"
                      ? infos.autreModeHebergementBenin
                      : infos.modeHebergementBenin
                  }}</strong>
                </b-col>
                <b-col md="6">
                  Pays de provenance :
                  <strong>{{
                    infos.paysProvenence && infos.paysProvenence
                  }}</strong>
                </b-col>
                <b-col md="6">
                  Pays de résidence :
                  <strong>{{
                    infos.paysResidence && infos.paysResidence
                  }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- destination -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>DESTINATION </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('destination')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6">
                  Ville :
                  <strong>
                    {{ infos.destination && infos.destination.ville }}</strong
                  >
                </b-col>
                <b-col md="6">
                  Pays :
                  <strong>
                    {{ infos.destination && infos.destination.pays }}</strong
                  >
                </b-col>
                <b-col md="6">
                  <!-- Motif principal du voyage -->
                  {{
                    form_frontiere_entree_label_q17_q18(infos.paysResidence)[0]
                  }}
                  <strong>{{
                    infos.motifVoyage === "Autres"
                      ? infos.autreMotifVoyage
                      : infos.motifVoyage
                  }}</strong>
                </b-col>
                <b-col md="6">
                  <!-- Durée du séjour : -->
                  {{
                    form_frontiere_entree_label_q17_q18(infos.paysResidence)[1]
                  }}
                  <strong>{{ infos.dureeSejour && infos.dureeSejour }}</strong>
                </b-col>
                <b-col md="6">
                  Catégorie Socio-Professionnelle :
                  <strong>{{
                    infos.categorieSocioPro === "Autres"
                      ? infos.autreCategorieSocioPro
                      : infos.categorieSocioPro
                  }}</strong>
                </b-col>
              </b-row>
            </div>

            <!-- voyage -->
            <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong>VOYAGE </strong>
                </b-col>
                <b-col md="2" class="text-right text-white pr-2">
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('voyage')"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="customborder1 p-2">
              <b-row>
                <b-col md="6">
                  Titre du voyage :
                  <strong v-if="infos.titreVoyage">
                    {{
                      infos.titreVoyage.titre === "Autres"
                        ? infos.autreTitreVoyage
                        : infos.titreVoyage.titre
                    }}
                  </strong>
                </b-col>
                <b-col md="6">
                  Date de délivrance :
                  <strong>
                    {{
                      infos.titreVoyage && infos.titreVoyage.dateDelivrance
                    }}</strong
                  >
                </b-col>
                <b-col md="6">
                  Lieu de délivrance :
                  <strong>
                    {{
                      infos.titreVoyage && infos.titreVoyage.lieuDelivrance
                    }}</strong
                  >
                </b-col>
                <b-col md="6">
                  Date d'expiration :
                  <strong>
                    {{
                      infos.titreVoyage && infos.titreVoyage.dateExpiration
                    }}</strong
                  >
                </b-col>
                <b-col md="6">
                  Numero du véhicule <strong>{{ infos.numeroVehicule }}</strong>
                </b-col>
                <b-col md="6">
                  Nom et Prénom du conducteur :
                  <strong>{{ infos.fullnameConducteur }}</strong>
                </b-col>
                <b-col md="6">
                  Numero du permis de conduire :
                  <strong>{{ infos.numeroPermisConduire }}</strong>
                </b-col>
                <b-col md="6">
                  Accompagner de :
                  <strong>{{ infos.nombreAccompagnants }}</strong> (Nombre
                  d'enfants)
                </b-col>
              </b-row>
            </div>

            <!-- RESERVE À L'ADMINISTRATION -->
            <!-- <div class="bg-primary">
              <b-row class="text-center mt-1 p-1">
                <b-col
                  md="10"
                  class="text-left text-white d-flex align-items-center"
                >
                  <strong> RÉSERVÉ À L'ADMINISTRATION </strong>
                </b-col>
                <b-col
                  md="2"
                  class="text-right text-white pr-2"
                >
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIcon"
                    @click="editSection('reserve-admin')"
                  />
                </b-col>
              </b-row>
            </div> -->

            <!-- <div class="customborder1 p-2">
              <b-row>
                <b-col md="6">
                  Numéro Visa :
                  <strong>
                    {{
                      infos.reserveAdministration &&
                        infos.reserveAdministration.visa &&
                        infos.reserveAdministration.visa.numeroVisa
                    }}</strong>
                </b-col>
                <b-col md="6">
                  Délivrée par :
                  <strong>
                    {{
                      infos.reserveAdministration &&
                        infos.reserveAdministration.visa &&
                        infos.reserveAdministration.visa.delivreePar
                    }}</strong>
                </b-col>
                <b-col md="6">
                  Date de délivrance :
                  <strong>
                    {{
                      infos.reserveAdministration &&
                        infos.reserveAdministration.visa &&
                        infos.reserveAdministration.visa.dateDelivrance
                    }}</strong>
                </b-col>
                <b-col md="6">
                  Duréé de validité :
                  <strong>
                    {{
                      infos.reserveAdministration &&
                        infos.reserveAdministration.visa &&
                        infos.reserveAdministration.visa.dureeValidite &&
                        infos.reserveAdministration.visa.dureeValidite.annee
                    }}</strong>
                  ans
                  <strong>
                    {{
                      infos.reserveAdministration &&
                        infos.reserveAdministration.visa &&
                        infos.reserveAdministration.visa.dureeValidite &&
                        infos.reserveAdministration.visa.dureeValidite.mois
                    }}</strong>
                  mois
                </b-col>
              </b-row>
            </div> -->
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center">
                <div class="mr-2"><b-spinner /></div>
                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <hr />
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  BImg,
  BSpinner,
  VBTooltip,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapMutations } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";
import formulaireStoreModule from "@/store/formulaire";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import { dataTool } from "echarts/lib/echarts";
import FormHeader from "./sections/FormHeader.vue";
import FormRenseignementsGeneraux from "./sections/FormRenseignementsGeneraux.vue";
import FormIdentite from "./sections/FormIdentite.vue";
import FormDestination from "./sections/FormDestination.vue";
import FormEmplois from "./sections/emplois/FormEmplois.vue";
import FormSalaries from "./sections/emplois/FormSalaries.vue";
import FormNpuveauxEmployesDuMois from "./sections/emplois/FormNpuveauxEmployesDuMois.vue";
import FormNonSalaries from "./sections/emplois/FormNonSalaries.vue";
import FormVoyage from "./sections/FormVoyage.vue";
import FormReserveAdministration from "./sections/FormReserveAdministration.vue";

const validInput = {};
export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    BModal,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,

    // entete
    FormHeader,

    // renseignements generaux
    FormRenseignementsGeneraux,

    // emplois
    FormEmplois,
    FormSalaries,
    FormNpuveauxEmployesDuMois,
    FormNonSalaries,
    FormIdentite,
    FormDestination,
    FormVoyage,
    FormReserveAdministration,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isSavingForm: false,
      infos: null,
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    if (this.$route.params.data && this.$route.params.preview) {
      const { data, isEditionOperation } = this.$route.params;

      this.infos = {
        ...data,
        // ...(isEditionOperation && { editor: this.currentUserId }),
      };
      const userData = localstorageService.getUserData();
      // console.log('userData.role::: ', userData.role);
      if (userData.role.code === "commissaire") {
        // eslint-disable-next-line no-underscore-dangle
        this.infos.commissariat = userData;
      } else {
        this.infos.commissariat = userData.commissariatAssociated;
      }
      // console.log(this.infos)
      // console.log(userData)
      if (isEditionOperation) this.infos.editor = this.currentUserId;

      this.preview = this.$route.params.preview;
    } else {
      // this.infos = validInput
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapMutations("auth", {
      callUserToRegister: "setter_callToRegister",
    }),
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
    },
    getSectionToEditComponent(section) {
      switch (section) {
        case "header":
          return "FormHeader";
        case "renseignements-generaux":
          return "FormRenseignementsGeneraux";
        case "identite":
          return "FormIdentite";
        case "destination":
          return "FormDestination";
        case "voyage":
          return "FormVoyage";
        case "reserve-admin":
          return "FormReserveAdministration";
        default:
          break;
      }
    },
    isBeninResident(country) {
      if (country && country.name) return country.name === "Bénin";
      return country && country === "Bénin";
    },
    save() {
      const { isCommissaire, isAgentPolice } =
        utilsService.currentUserUtils();
      this.isSavingForm = true;
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;
      if (this.infos.numeroVehicule === "" || !this.infos.numeroVehicule)
        delete this.infos.numeroVehicule;
      if (
        this.infos.numeroPermisConduire === "" ||
        !this.infos.numeroPermisConduire
      )
        delete this.infos.numeroPermisConduire;
      if (
        this.infos.numeroVehicule === null ||
        this.infos.numeroVehicule === ""
      )
        delete this.infos.numeroVehicule;
      if (
        this.infos.fullnameConducteur === null ||
        this.infos.fullnameConducteur === ""
      )
        delete this.infos.fullnameConducteur;
      if (
        this.infos.numeroPermisConduire === null ||
        this.infos.numeroPermisConduire === ""
      )
        delete this.infos.numeroPermisConduire;
      if (
        this.infos.nombreAccompagnants === null ||
        this.infos.nombreAccompagnants === ""
      )
        delete this.infos.nombreAccompagnants;

      if (this.infos.modeHebergementBenin === "Autres") {
        this.infos.modeHebergementBenin = this.infos.autreModeHebergementBenin;
      }
      if (this.infos.modeHebergementEtranger === "Autres") {
        this.infos.modeHebergementEtranger =
          this.infos.autreModeHebergementEtranger;
      }
      if (this.infos.motifVoyage === "Autres") {
        this.infos.motifVoyage = this.infos.autreMotifVoyage;
      }
      if (this.infos.categorieSocioPro === "Autres") {
        this.infos.categorieSocioPro = this.infos.autreCategorieSocioPro;
      }
      if (this.infos.titreVoyage.titre === "Autres") {
        this.infos.titreVoyage.titre = this.infos.autreTitreVoyage;
      }
      delete this.infos.autreModeHebergementBenin;
      delete this.infos.autreModeHebergementEtranger;
      delete this.infos.autreMotifVoyage;
      delete this.infos.autreCategorieSocioPro;
      delete this.infos.autreTitreVoyage;
      delete this.infos.commune;
      delete this.infos.quartier;

      const isBeninResident = this.isBeninResident(this.infos.paysResidence);

      if (!isBeninResident) {
        delete this.infos.modeHebergementEtranger;
      } else {
        delete this.infos.modeHebergementBenin;
      }
      const data = {
        formType: "forms-frontiere-entree-voyageurs",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
          npi: `${this.infos.npi}`,
          nationalite: this.infos.nationalite,
          paysProvenence: this.infos.paysProvenence,
          paysResidence: this.infos.paysResidence,
        },
      };

      const userData = localstorageService.getUserData();
      if (userData._id) {
        data.data.auteur = userData._id;
        data.data.auteurRole = userData.role._id;
        if(isCommissaire){
          data.data.departement = userData.departement;
          data.data.commune = userData.commune;
          data.data.arrondissement = userData.arrondissement;
        }
        if(isAgentPolice){
          data.data.departement = userData.commissariatAssociated.departement;
          data.data.commune = userData.commissariatAssociated.commune;
          data.data.arrondissement = userData.commissariatAssociated.arrondissement;
        }
      } else {
        delete data.data.auteur;
      }

      data.data.commissariat = this.infos.commissariat._id;

      delete data.data.dateSortie;

      // if (etablissementDetails._id) { data.data.etablissement = etablissementDetails._id }
      // if (etablissementDetails.secteur) { data.data.secteur = etablissementDetails.secteur }
      // return

      this.addFormulaire(data)
        .then((response) => {
          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              // let selectedForm = utilsService.getFormulaireBySlug(this.$route.params.slug)
              // console.log('selectedForm: 🔵🔴🔵🔴 ', selectedForm)
              // const { params } = selectedForm.createRouteProps
              // this.$router.push({
              //   ...selectedForm.createRouteProps,
              //   params: {
              //     ...params,
              //   },
              // })

              this.$router.push({
                name: "dashboard",
              });

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            } else {
              this.$router.push({
                name: "dashboard",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            }
            const callToRegisterFormData = {
              nom: this.infos.firstname,
              prenom: this.infos.lastname,
              email: "",
              telephone: "",
            };
            this.callUserToRegister({
              status: true,
              data: callToRegisterFormData,
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = this.customCloneDeep(this.infos);
      this.sectionToEditData.destination.pays = this.infos.destination.pays;
      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
