var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"renseignementsgeneraux"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("VOYAGE")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_vm._v("Titre de voyage")]),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"ml-2",attrs:{"options":['Passeport', 'CNI', 'Autres'],"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.form.titreVoyage.titre),callback:function ($$v) {_vm.$set(_vm.form.titreVoyage, "titre", $$v)},expression:"form.titreVoyage.titre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.titreVoyage.titre === 'Autres')?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline",attrs:{"type":"text","placeholder":"Veuillez préciser le titre de voyage"},model:{value:(_vm.autreTitreVoyage),callback:function ($$v) {_vm.autreTitreVoyage=$$v},expression:"autreTitreVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3603611211)}):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Date de délivrance"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Veuillez préciser la date de délivrance","max":_vm.today},model:{value:(_vm.form.titreVoyage.dateDelivrance),callback:function ($$v) {_vm.$set(_vm.form.titreVoyage, "dateDelivrance", $$v)},expression:"form.titreVoyage.dateDelivrance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Lieu de delivrance"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Veuillez préciser le lieu de délivrance"},model:{value:(_vm.form.titreVoyage.lieuDelivrance),callback:function ($$v) {_vm.$set(_vm.form.titreVoyage, "lieuDelivrance", $$v)},expression:"form.titreVoyage.lieuDelivrance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Date d'expiration"}},[_c('validation-provider',{attrs:{"rules":{required: _vm.required, date_between: [_vm.today, null, false]}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Veuillez préciser la date d'expiration"},model:{value:(_vm.form.titreVoyage.dateExpiration),callback:function ($$v) {_vm.$set(_vm.form.titreVoyage, "dateExpiration", $$v)},expression:"form.titreVoyage.dateExpiration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Numéro de véhicule (Facultatif)"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Veuillez entrer le numéro de véhicule"},model:{value:(_vm.form.numeroVehicule),callback:function ($$v) {_vm.$set(_vm.form, "numeroVehicule", $$v)},expression:"form.numeroVehicule"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Nom et prénom du conducteur"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Veuillez entrer le nom et le prénom du conducteur"},model:{value:(_vm.form.fullnameConducteur),callback:function ($$v) {_vm.$set(_vm.form, "fullnameConducteur", $$v)},expression:"form.fullnameConducteur"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Numéro du permis de conduire (Facultatif)"}},[_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 15}),expression:"{length: 15}"}],attrs:{"type":"text","placeholder":"Veuillez entrer le numéro du permis de conduire"},model:{value:(_vm.form.numeroPermisConduire),callback:function ($$v) {_vm.$set(_vm.form, "numeroPermisConduire", $$v)},expression:"form.numeroPermisConduire"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Accompagné de (Nombre d’enfants) (pour une famille en déplacement l’information sera renseignée pour un seul conjoint)"}},[_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 10}),expression:"{length: 10}"}],attrs:{"type":"number","max":"","placeholder":"Accompagné de..."},model:{value:(_vm.form.nombreAccompagnants),callback:function ($$v) {_vm.$set(_vm.form, "nombreAccompagnants", $$v)},expression:"form.nombreAccompagnants"}})],1)],1)],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }