<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <!-- inputData.destination = {{ inputData.destination }} -->
      <b-col lg="12" class="pb-2">
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card id="renseignementsgeneraux">
            <b-card-header class="d-flex justify-content-between">
              <h3>DESTINATION</h3>
            </b-card-header>
            <hr />
            <b-card-body>
              <b-row>
                <!-- DESTINATION (Ville/Pays) -->
                <b-col v-if="form.destination" md="12">
                  <div>Destination</div>
                  <b-row class="">
                    <!-- Pays -->
                    <b-col md="6">
                      <b-form-group label-cols-lg="12" label="Pays">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <br />
                          <SelectCountriesList
                            v-model="form.destination.pays"
                            :inputValue="form.destination.pays"
                            :placeholder="'Sélectionnez le pays de destination'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Ville -->
                    <b-col md="6">
                      <b-form-group label-cols-lg="12" label="Ville">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.destination.ville"
                            type="text"
                            placeholder="Veuillez entrer le nom de la ville"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- MOTIF PRINCIPAL DU VOYAGE (Reason for travel)  -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    :label="form_frontiere_entree_label_q17_q18(form.paysResidence)[0]"
                  >
                    <validation-provider v-slot="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="form.motifVoyage"
                        :options="motifPrincipalVoyage"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      v-if="form.motifVoyage === 'Autres'"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="autreMotifVoyage"
                        class="d-inline mt-1"
                        type="text"
                        placeholder="Veuillez préciser le motif du voyage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- DUREE DE SEJOUR (Length of stay)  -->
                <b-col md="12">
                  <b-form-group label-cols-lg="12" 
                    :label="form_frontiere_entree_label_q17_q18(form.paysResidence)[1]"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required }"
                    >
                      <b-form-input
                        v-model="form.dureeSejour"
                        type="number"
                        placeholder="Veuillez préciser la durée de séjour du voyageur"
                        class="form-control"
                        v-digits-only
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- CATEGORIE SOCIO-PROfESSIONNELLE/Socio-professional category  -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Catégorie socio-professionnelle"
                  >
                    <validation-provider v-slot="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="form.categorieSocioPro"
                        :options="categorieSocioProList"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                        class=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      v-if="form.categorieSocioPro === 'Autres'"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="autreCategorieSocioPro"
                        class="d-inline mt-1"
                        type="text"
                        placeholder="Veuillez préciser la catégorie socio-professionnelle"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col cols="12" class="my-2 text-right">
        <div class="">
          <b-button variant="outline-secondary" @click="onCancel">
            Cancel
          </b-button>
          <b-button variant="primary" @click="submitForm"> Modifier </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
// import axios from 'axios'
import { mapActions, mapState } from "vuex";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import moment from "moment";
import SelectCountriesList from "@/components/SelectCountriesList.vue";

import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import utilsService from "@/services/utils/utils.service";

// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    flatPickr,
    SelectCountriesList,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      villes: [],
      pays: [],
      motifPrincipalVoyage: [
        {
          text: "Affaires et motifs professionnels",
          value: "Affaires et motifs professionnels",
        },
        {
          text: "Vacances, loisirs et détente",
          value: "Vacances, loisirs et détente",
        },
        {
          text: "Visites à des parents ou des amis",
          value: "Visites à des parents ou des amis",
        },
        { text: "Éducation et formation", value: "Éducation et formation" },
        { text: "Santé et soins médicaux", value: "Santé et soins médicaux" },
        { text: "Religion ou pèlerinage", value: "Religion ou pèlerinage" },
        { text: "Achats", value: "Achats" },
        { text: "Transit", value: "Transit" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      modesHebergement: [
        { text: "Hôtel et assimilés ", value: "Hôtel et assimilés " },
        { text: "Famille/Amis ", value: "Famille/Amis" },
        { text: "Autres", value: "Autres" },
      ],
      categorieSocioProList: [
        { text: "Libéral/indépendant", value: "Libéral/indépendant" },
        { text: "Employé/salarié", value: "Employé/salarié" },
        { text: "Retraité", value: "Retraité" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      form: {
        dateSortie: "",

        reserveAdministration: {
          commisariat: "",
          visa: {
            delivreePar: "",
            numeroVisa: "",
            dateDelivrance: "",
            dureeValidite: {
              annee: "",
              mois: "",
            },
          },
        },
        date: "",
        heure: "",
        lastname: "",
        firstname: "",
        maidenname: "",
        sexe: "",
        dateNaissance: "",
        lieuNaissance: "",
        nationalite: "",
        paysResidence: "",
        adresseEtranger: "",
        adresseBenin: "",
        modeHebergementBenin: "",
        modeHebergementEtranger: "",
        paysProvenence: "",
        destination: {
          ville: "",
          pays: "",
        },
        motifVoyage: "",
        dureeSejour: "",
        categorieSocioPro: "",
        titreVoyage: {
          titre: "",
          dateDelivrance: "",
          lieuDelivrance: "",
          dateExpiration: "",
        },
        numeroVehicule: "",
        fullnameConducteur: "",
        numeroPermisConduire: "",
        nombreAccompagnants: "",
      },
      autreMotifVoyage: "",
      autreCategorieSocioPro: "",
      today: moment().format("YYYY-MM-DD"),
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        // this.getListEtablissement()
      },
    },
    "form.dureeSejour": {
      deep: true,
      handler(val, old) {
        if (this.form.date) {
          const dateEntree = this.form.date
            .split("-")
            .map((e) => parseInt(e, 10));
          dateEntree[1] -= 1;
          const dateEntreeMoment = moment(dateEntree);
          dateEntreeMoment.add(this.form.dureeSejour, "days");
          this.form.dateSortie = dateEntreeMoment.format("YYYY-MM-DD");
        }
      },
    },
  },
  mounted() {
    this.villes = utilsService.getCommunes();
    this.pays = utilsService.getListCountries();
    if (this.inputData) {
      this.form = this.customCloneDeep(this.inputData);
      this.form.destination.pays = this.inputData.destination.pays;
    } else if (this.$route.params.data) {
      this.form = this.$route.params.data;
    } else {
      // this.infos = this.validOutput
    }

    // console.log(
    //   "this.inputData.destination.pays::: 🟠",
    //   this.inputData.destination.pays
    // );
    console.log("this.form.destination.pays::: 🟠", this.form.destination.pays);
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit("onCancel", {});
    },

    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then((success) => {
        if (success) {
          if (this.autreMotifVoyage)
            this.form.motifVoyage = this.autreMotifVoyage;
          if (this.autreCategorieSocioPro)
            this.form.categorieSocioPro = this.autreCategorieSocioPro;
          // calculate nombreOfDays -------------------------------------
          // const { dureeSejour, dateSortie } = this.form;
          // if (this.form.date) {
          //   const date = this.form.date.split("-").map((e) => parseInt(e, 10));
          //   date[1] -= 1;
          //   const futurDate = moment(dateSortie);
          //   const diffNbreJours = futurDate.diff(moment(date), "days");
          //   this.form.dureeSejour = diffNbreJours;
          // }
          // return
          // end calculate nombreOfDays ----------------------------------
          const data = {
            ...this.form,
          };
          if (this.inputData) {
            this.$emit("onFormSuccess", data);
          } else {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: data },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
