var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"renseignementsgeneraux"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("DESTINATION")])]),_c('hr'),_c('b-card-body',[_c('b-row',[(_vm.form.destination)?_c('b-col',{attrs:{"md":"12"}},[_c('div',[_vm._v("Destination")]),_c('b-row',{},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Pays"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('br'),_c('SelectCountriesList',{attrs:{"inputValue":_vm.form.destination.pays,"placeholder":'Sélectionnez le pays de destination'},model:{value:(_vm.form.destination.pays),callback:function ($$v) {_vm.$set(_vm.form.destination, "pays", $$v)},expression:"form.destination.pays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,467111768)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Ville"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Veuillez entrer le nom de la ville"},model:{value:(_vm.form.destination.ville),callback:function ($$v) {_vm.$set(_vm.form.destination, "ville", $$v)},expression:"form.destination.ville"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,465514268)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":_vm.form_frontiere_entree_label_q17_q18(_vm.form.paysResidence)[0]}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.motifPrincipalVoyage,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.form.motifVoyage),callback:function ($$v) {_vm.$set(_vm.form, "motifVoyage", $$v)},expression:"form.motifVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.motifVoyage === 'Autres')?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline mt-1",attrs:{"type":"text","placeholder":"Veuillez préciser le motif du voyage"},model:{value:(_vm.autreMotifVoyage),callback:function ($$v) {_vm.autreMotifVoyage=$$v},expression:"autreMotifVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2937668702)}):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":_vm.form_frontiere_entree_label_q17_q18(_vm.form.paysResidence)[1]}},[_c('validation-provider',{attrs:{"rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Veuillez préciser la durée de séjour du voyageur"},model:{value:(_vm.form.dureeSejour),callback:function ($$v) {_vm.$set(_vm.form, "dureeSejour", $$v)},expression:"form.dureeSejour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Catégorie socio-professionnelle"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.categorieSocioProList,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.form.categorieSocioPro),callback:function ($$v) {_vm.$set(_vm.form, "categorieSocioPro", $$v)},expression:"form.categorieSocioPro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.categorieSocioPro === 'Autres')?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline mt-1",attrs:{"type":"text","placeholder":"Veuillez préciser la catégorie socio-professionnelle"},model:{value:(_vm.autreCategorieSocioPro),callback:function ($$v) {_vm.autreCategorieSocioPro=$$v},expression:"autreCategorieSocioPro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,728631343)}):_vm._e()],1)],1)],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }