var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',{attrs:{"id":"renseignementsgeneraux"}},[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RESERVE À L'ADMINISTRATION ")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"VISA N°"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(
                            _vm.form.reserveAdministration.visa.numeroVisa
                          ),callback:function ($$v) {_vm.$set(_vm.form.reserveAdministration.visa, "numeroVisa", $$v)},expression:"\n                            form.reserveAdministration.visa.numeroVisa\n                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"DELIVREE PAR"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(
                            _vm.form.reserveAdministration.visa.delivreePar
                          ),callback:function ($$v) {_vm.$set(_vm.form.reserveAdministration.visa, "delivreePar", $$v)},expression:"\n                            form.reserveAdministration.visa.delivreePar\n                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Date de delivrance"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Date de délivrance"},model:{value:(
                            _vm.form.reserveAdministration.visa
                              .dateDelivrance
                          ),callback:function ($$v) {_vm.$set(_vm.form.reserveAdministration.visa
                              , "dateDelivrance", $$v)},expression:"\n                            form.reserveAdministration.visa\n                              .dateDelivrance\n                          "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Durée validité"}},[_c('div',{staticClass:"d-flex align-items-center gap-10"},[_c('validation-provider',{staticClass:"d-inline-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(
                              _vm.form.reserveAdministration.visa
                                .dureeValidite.annee
                            ),callback:function ($$v) {_vm.$set(_vm.form.reserveAdministration.visa
                                .dureeValidite, "annee", $$v)},expression:"\n                              form.reserveAdministration.visa\n                                .dureeValidite.annee\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"d-inline-block"},[_vm._v(" annees ")]),_c('validation-provider',{staticClass:"d-inline-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(
                              _vm.form.reserveAdministration.visa
                                .dureeValidite.mois
                            ),callback:function ($$v) {_vm.$set(_vm.form.reserveAdministration.visa
                                .dureeValidite, "mois", $$v)},expression:"\n                              form.reserveAdministration.visa\n                                .dureeValidite.mois\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" mois ")],1)])],1)],1)],1)],1)],1)],1)],1)])],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }