<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card id="renseignementsgeneraux">
            <b-card-header class="d-flex justify-content-between">
              <h3>IDENTITE</h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <!-- Date -->
              <b-row>
                <!-- lastname -->
                <b-col md="6">
                  <b-form-group
                    label-cols-md="12"
                    label="Nom"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|alpha"
                    >
                      <b-form-input
                        v-model="form.lastname"
                        type="text"
                        placeholder="Veuillez entrer votre nom"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- firstname -->
                <b-col md="6">
                  <b-form-group
                    label-cols-lg="12"
                    label="Prénom(s)"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|alpha"
                    >
                      <b-form-input
                        v-model="form.firstname"
                        type="text"
                        placeholder="Veuillez entrer votre prénom"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- sexe -->
                <b-col lg="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Sexe"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="form.sexe"
                        :options="sexeList"
                        class="ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- maidenname -->
                <b-col
                  v-if="form.sexe === 'Féminin'"
                  md="12"
                >
                  <b-form-group
                    label-cols-lg="12"
                    label="Nom de jeune fille"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                    >
                      <b-form-input
                        v-model="form.maidenname"
                        type="text"
                        placeholder="Veuillez entrer le nom de la jeune fille"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col lg="12">
                  <!-- date naissance -->
                  <b-form-group
                    class="mt-2"
                    label-cols-lg="12"
                    label="Date de naissance"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|beforeOrToday|valideBirthdate"
                    >
                      <b-form-input
                        v-model="form.dateNaissance"
                        lang="fr-CA"
                        type="date"
                        placeholder="Veuillez saisir la date de naissance"
                        class="form-control"
                        :max="today"
                      />

                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- lieu naissance -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Lieu de naissance"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.lieuNaissance"
                        type="text"
                        placeholder="Veuillez entrer votre lieu de naissance"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Nationalite -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Nationalité"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >

                      <SelectCountriesList
                        v-model="form.nationalite"
                        :inputValue="form.nationalite"
                        :placeholder="'Sélectionnez le pays de votre  nationalité'"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- pays residence -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Pays de résidence"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <SelectCountriesList
                        v-model="form.paysResidence"
                        :inputValue="form.paysResidence"
                        :placeholder="'Sélectionnez le pays de residence'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- adresse etranger -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Adresse à l'étranger"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.adresseEtranger"
                        type="text"
                        placeholder="Veuillez entrer votre adresse à l'étranger"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- mode hebergement benin -->
                <b-col
                  v-if="form.paysResidence && !isBeninResident(form.paysResidence)"
                  md="12"
                >
                  <b-form-group
                    label-cols-lg="12"
                    label="Mode d'hébergement au Bénin"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="form.modeHebergementBenin"
                        :options="modesHebergement"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      v-if="form.modeHebergementBenin === 'Autres'"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="autreModeHebergementBenin"
                        class="d-inline"
                        type="text"
                        placeholder="Veuillez préciser le mode d'hébergement"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- mode hebergement etranger -->
                <b-col
                  v-if="form.paysResidence && isBeninResident(form.paysResidence)"
                  md="12"
                >
                  <b-form-group
                    label-cols-lg="12"
                    label="Mode d'hébergement à l'étranger"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="form.modeHebergementEtranger"
                        :options="modesHebergement"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                      v-if="form.modeHebergementEtranger === 'Autres'"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="autreModeHebergementEtranger"
                        class="d-inline"
                        type="text"
                        placeholder="Veuillez préciser le mode d'hébergement"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- ADRESSE AU BENIN /Address in Benin -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Adresse au Bénin"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.adresseBenin"
                        type="text"
                        placeholder="Veuillez entrer votre adresse au Bénin"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- PAYS DE PROVENANCE /Country of origin -->
                <b-col md="12">
                  <b-form-group
                    label-cols-lg="12"
                    label="Pays de provenance"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <SelectCountriesList
                        :inputValue="form.paysProvenence"
                        :placeholder="'Sélectionnez le pays de provenance'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utilsService from '@/services/utils/utils.service'
import SelectCountriesList from '@/components/SelectCountriesList.vue'
import moment from 'moment'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    flatPickr,
    SelectCountriesList
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      pays: [],
      modesHebergement: [
        { text: 'Hôtel et assimilés ', value: 'Hôtel et assimilés ' },
        { text: 'Famille/Amis ', value: 'Famille/Amis' },
        { text: 'Autres', value: 'Autres' },
      ],
      sexeList: [
        { text: 'Masculin', value: 'Masculin' },
        { text: 'Féminin', value: 'Féminin' },
      ],
      autreModeHebergementBenin: '',
      autreModeHebergementEtranger: '',
      autreMotifVoyage: '',
      autreCategorieSocioPro: '',
      autreTitreVoyage: '',
      form: {
        reserveAdministration: {
          commisariat: '',
          visa: {
            delivreePar: '',
            numeroVisa: '',
            dateDelivrance: '',
            dureeValidite: {
              annee: '',
              mois: '',
            },
          },
        },
        date: '',
        heure: '',
        lastname: '',
        firstname: '',
        maidenname: '',
        sexe: '',
        dateNaissance: '',
        lieuNaissance: '',
        nationalite: '',
        paysResidence: '',
        adresseEtranger: '',
        adresseBenin: '',
        modeHebergementBenin: '',
        modeHebergementEtranger: '',
        paysProvenence: '',
        destination: {
          ville: '',
          pays: '',
        },
        motifVoyage: '',
        dureeSejour: '',
        categorieSocioPro: '',
        titreVoyage: {
          titre: '',
          dateDelivrance: '',
          lieuDelivrance: '',
          dateExpiration: '',
        },
        numeroVehicule: '',
        fullnameConducteur: '',
        numeroPermisConduire: '',
        nombreAccompagnants: '',
      },
      today: moment().format('YYYY-MM-DD'),
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        // this.getListEtablissement()
      },
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    
    this.pays = utilsService.getListCountries()

    // this.loadQuartier();
    if (this.inputData) {
      this.form = this.inputData
    } else if (this.$route.params.data) {
      this.form = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    isBeninResident(country) {
      if (country && country.name) return country.name === 'Bénin'
      return country && country === 'Bénin'
    },
    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },

    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }
          const form = {
            ...this.form,
            ...(this.form.modeHebergementBenin === 'Autres' && { modeHebergementBenin: this.autreModeHebergementBenin }),
            ...(this.form.modeHebergementEtranger === 'Autres' && { modeHebergementEtranger: this.autreModeHebergementEtranger }),
            ...(this.form.motifVoyage === 'Autres' && { motifVoyage: this.autreMotifVoyage }),
            ...(this.form.categorieSocioPro === 'Autres' && { categorieSocioPro: this.autreCategorieSocioPro }),
            ...(this.form.titreVoyage.titre === 'Autres' && {
              titreVoyage: {
                ...this.form.titreVoyage,
                titre: this.autreTitreVoyage,
              },
            }),
          }

          if (this.inputData) {
            this.$emit('onFormSuccess', form)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.form },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
