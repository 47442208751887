<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card id="renseignementsgeneraux">
            <b-card-header class="d-flex justify-content-between">
              <h3>RESERVE À L'ADMINISTRATION
              </h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <b-row>
                <!-- Reserve dmin -->
                <b-col md="12">

                  <b-row class="">
                    <b-col md="12">
                      <b-form-group
                        label-cols-lg="12"
                        label="VISA N°"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="
                              form.reserveAdministration.visa.numeroVisa
                            "
                            type="text"
                          />
                          <small class="text-danger">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols-lg="12"
                        label="DELIVREE PAR"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="
                              form.reserveAdministration.visa.delivreePar
                            "
                            type="text"
                          />
                          <small class="text-danger">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols-lg="12"
                        label="Date de delivrance"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="
                              form.reserveAdministration.visa
                                .dateDelivrance
                            "
                            class="form-control"
                            placeholder="Date de délivrance"
                          />
                          <small class="text-danger">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols-lg="12"
                        label="Durée validité"
                      >
                        <div class="d-flex align-items-center gap-10">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            class="d-inline-block"
                          >
                            <b-form-input
                              v-model="
                                form.reserveAdministration.visa
                                  .dureeValidite.annee
                              "
                              type="text"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                          <div class="d-inline-block">
                            annees
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            class="d-inline-block"
                          >
                            <b-form-input
                              v-model="
                                form.reserveAdministration.visa
                                  .dureeValidite.mois
                              "
                              type="text"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                          mois
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  // BListGroup,
  // BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utilsService from '@/services/utils/utils.service'

// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    // BListGroup,
    // BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  // computed: {
  //   ...mapState('params', {
  //     listPays: 'listPays',
  //     listMois: 'listMois',
  //     listAnnees: 'listAnnees',
  //   }),
  // },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  }, //
  data() {
    return {
      email,
      required,
      // email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      pays: [],
      modesHebergement: [
        { text: 'Hôtel et assimilés ', value: 'Hôtel et assimilés ' },
        { text: 'Famille/Amis ', value: 'Famille/Amis' },
        { text: 'Autres', value: 'Autres' },
      ],
      sexeList: [
        { text: 'Masculin/Male', value: 'Masculin/Male' },
        { text: 'Féminin/Female', value: 'Féminin/Female' },
      ],
      form: {
        reserveAdministration: {
          commisariat: '',
          visa: {
            delivreePar: '',
            numeroVisa: '',
            dateDelivrance: '',
            dureeValidite: {
              annee: '',
              mois: '',
            },
          },
        },
        date: '',
        heure: '',
        lastname: '',
        firstname: '',
        maidenname: '',
        sexe: '',
        dateNaissance: '',
        lieuNaissance: '',
        nationalite: '',
        paysResidence: '',
        adresseEtranger: '',
        adresseBenin: '',
        modeHebergementBenin: '',
        modeHebergementEtranger: '',
        paysProvenence: '',
        destination: {
          ville: '',
          pays: '',
        },
        motifVoyage: '',
        dureeSejour: '',
        categorieSocioPro: '',
        titreVoyage: {
          titre: '',
          dateDelivrance: '',
          lieuDelivrance: '',
          dateExpiration: '',
        },
        numeroVehicule: '',
        fullnameConducteur: '',
        numeroPermisConduire: '',
        nombreAccompagnants: '',
      },
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        // this.getListEtablissement()
      },
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    
    this.pays = utilsService.getListCountries()

    // this.loadQuartier();
    if (this.inputData) {
      this.form = this.inputData
    } else if (this.$route.params.data) {
      this.form = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },

  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },

    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },

    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.form)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.form },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
